import React from 'react'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'
import Container from './container'
import {Link} from 'gatsby'
import FontTester from './font-tester'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import '../styles/font-single.css'

var settings = {
  arrows: false,
  infinite: true,
  fade: true,
  speed: 700,
  autoplay: true,
  slidesToScroll: 1,
  swipeToSlide: true,
  dots: true,
};

function FontSingle (props) {
  const {_rawDescription, images} = props

  console.log(images)

  return (
    <Container>
      <div id="font-single" className="font-single">
        <div className="wrapper">
          <h3><Link to="/fonts/">Fonts</Link></h3>
          <h2>{props.title}</h2>
        </div>

          <div id="font-slides" className="font-slides">
            <Slider {...settings}>
            {images && images.map(image => {
                  return image.desktopImage.asset && image.mobileImage.asset && (
                  <div className="slide">
                    <picture>
                      <source 
                        media="(max-width: 699px)" 
                        srcset={imageUrlFor(buildImageObj(image.mobileImage))
                          .width(1000)
                          .height(Math.floor((1 / 1) * 1000))
                          .auto('format')
                          .url()}
                      />
                      <source 
                        media="(min-width: 700px)" 
                        srcset={imageUrlFor(buildImageObj(image.desktopImage))
                          .width(2000)
                          .height(Math.floor((1 / 2) * 2000))
                          .auto('format')
                          .url()}
                      />
                      <img
                        src={imageUrlFor(buildImageObj(image.desktopImage))
                          .width(2000)
                          .height(Math.floor((1 / 2) * 2000))
                          .auto('format')
                          .url()}
                        alt={image.desktopImage.alt}
                        title={image.desktopImage.alt}
                      />
                    </picture>
                  </div>
                )
                })}
            </Slider>
          </div>

          <div className="wrapper no-top-margin">
            <div className="grid">
              <div className="font-main width-12-12-m width-12-12">
                {props._rawDescription && (
                  <div className="body-holder">
                    {_rawDescription && <PortableText blocks={_rawDescription} />}
                  </div>
                )}
              </div>
              <div className="font-main width-12-12-m width-12-12">
                
                <FontTester {...props} />

              </div>
            </div>
          </div>
        </div>

    </Container>
  )
}

export default FontSingle