import React from 'react'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import '../styles/font-tester.css'
import {prettify} from '../lib/helpers'
import TextareaAutosize from 'react-textarea-autosize';

class FontTester extends React.Component {
  constructor(props) {
    super(props);

      this.initialState = {
        value: 54,
        min: 12,
        max: 96,
        valueh: 1.2,
        valuek: 0,
        valuevw: 86,
        valuevwi: 86,
        copy: props.tester
      };
      this.state = this.initialState;
      this.handleCopyChange = this.handleCopyChange.bind(this);
      this.toggleReset = this.toggleReset.bind(this);
  }

  componentWillReceiveProps(props) {
        this.setState({copy: props.tester});
    }

  onSliderChange = value => {
    this.setState({
      value,
    });
  };

  onSliderChangeh = valueh => {
    this.setState({
      valueh,
    });
  };

  onSliderChangevw = valuevw => {
    this.setState({
      valuevw,
    });
  };

  onSliderChangevwi = valuevwi => {
    this.setState({
      valuevwi,
    });
  };

  onSliderChangek = valuek => {
    this.setState({
      valuek,
    });
  };

  handleCopyChange(event) {
      this.setState({copy: event.target.value})
  }

  toggleReset(event) {
      this.setState(this.initialState)
      this.setState({copy: this.props.tester})
  }

  handleKeyDown = (event) => {
  // check keys if you want
    if (event.keyCode === 13) {
      //
    }
  }


  render() {

    //console.log(this.props.family)

    return (
      <div className="font-tester">

        <div className="controls grid">
          <div className="rc-slider-holder width-3-12 width-6-12-m">
            <div class="small-a small-space"></div>
            <Slider
              value={this.state.valueh}
              onChange={this.onSliderChangeh}
              min={1}
              max={2}
              step={.02}
            />
          </div>
          <div className="rc-slider-holder width-3-12 width-6-12-m">
            <div class="small-a small-kern"></div>
            <Slider
              value={this.state.valuek}
              onChange={this.onSliderChangek}
              min={-2}
              max={20}
              step={.02}
            />
          </div>
          <div className="rc-slider-holder width-3-12 width-6-12-m">
            <div class="small-a">A</div>
            <Slider
              value={this.state.value}
              onChange={this.onSliderChange}
              min={this.state.min}
              max={this.state.max}
            />
            <div class="big-a">A</div>
          </div>
          <div className="rc-slider-holder width-3-12 width-6-12-m">
            <div role="button" aria-label="Reset Font Tester" tabIndex={0} onClick={this.toggleReset} onKeyDown={this.handleKeyDown} class="small-reset"></div>
          </div>
        </div>
        

        <div className="grid family-holder">
        {this.props.family &&
            this.props.family.map((value, i) => {
              return (
              <div className="disp width-12-12 width-12-12-m">
                <p className="header">{this.props.title} {prettify(this.props.family[i])}</p>
                

                {(this.props.family[i] === 'variable' ? 
                  (
                    <>
                    <TextareaAutosize className="test" style={{fontSize: this.state.value + 'px', fontVariationSettings: '"wght" ' + this.state.valuevw, fontFamily: this.props.slug.current+'-'+this.props.family[i], lineHeight: this.state.valueh, letterSpacing: this.state.valuek + 'px'}} value={this.state.copy} onChange={this.handleCopyChange} />
                    <div className="grid">
                      <div className="rc-slider-holder width-3-12 width-6-12-m">
                      <div class="light-b">B</div>
                      <Slider
                        value={this.state.valuevw}
                        onChange={this.onSliderChangevw}
                        min={0}
                        max={1000}
                        step={50}
                      />
                      <div class="strong-b">B</div>
                      </div>
                    </div>
                    </>
                  )

                : (this.props.family[i] === 'variable-italic' ?

                  (
                    <>
                    <TextareaAutosize className="test" style={{fontSize: this.state.value + 'px', fontVariationSettings: '"wght" ' + this.state.valuevwi, fontFamily: this.props.slug.current+'-'+this.props.family[i], lineHeight: this.state.valueh, letterSpacing: this.state.valuek + 'px'}} value={this.state.copy} onChange={this.handleCopyChange} />
                    <div className="grid">
                      <div className="rc-slider-holder width-3-12 width-6-12-m">
                      <div class="light-b">B</div>
                      <Slider
                        value={this.state.valuevwi}
                        onChange={this.onSliderChangevwi}
                        min={0}
                        max={1000}
                        step={50}
                      />
                      <div class="strong-b">B</div>
                      </div>
                    </div>
                    </>
                  ) 

                : (
                    <TextareaAutosize className="test" style={{fontSize: this.state.value + 'px', fontFamily: this.props.slug.current+'-'+this.props.family[i], lineHeight: this.state.valueh, letterSpacing: this.state.valuek + 'px'}} value={this.state.copy} onChange={this.handleCopyChange} />) 
                  )
                )}

              </div>
              )
            })
          }
        </div>
        
      </div>
    )
  }
}

export default FontTester